import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import './registrationPlan.css'

function RegistrationPlan() {
    const dataPlan = [
        {
          name: 'Basic',
          price: "5,000",
          features: ['Feature 1', 'Feature 2'],
        },
        {
          name: 'Standard',
          price: "50,000",
          features: ['Feature 1', 'Feature 2', 'Feature 3'],
        },
        {
          name: 'Premium',
          price: "100,000",
          features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
        }
      ];
      
    return (
        <div className='pb-5' style={{backgroundColor: 'rgb(245,249,253)', height: '100vh'}}>
                        <div className="container text-center pt-5">
                <div className='row'>
                    <h1>Plans and Pricing</h1>
                    <h3> Choose the right plan for your business and your needs</h3>
                </div>
                <div className='row'>
                    <section className="d-flex flex-row align-items-center justify-content-between mt-5 flex-wrap">
                        {
                            dataPlan.map((element,index)=>{
                                return(
                                    <Paper  className={`p-5 text-center rounded-3 ${index===0 && 'fading-blue'}`} elevation={8}>
                                        <p key={index} style={{color:index!==0?'rgb(152,155,166)':'white'}} className='fs-6 fw-medium'>{element.name}</p>
                                        <p key={index} style={{color: index===0 && 'white'}} className='fs-3 fw-bold'>{element.price} CFA</p>
                                        <ul style={{color:index!==0?'rgb(152,155,166)':'white'}}>
                                            {
                                                element.features.map((fts,idx)=>{
                                                    return(
                                                        <li key={idx}>{fts}</li>
                                                    );
                                                })
                                            }
                                        </ul>
                                        <Button style={{backgroundColor: index!==0?'rgb(152,155,166)':'white' , color: index === 0 && 'black' }} className='mt-4 px-5 fw-medium' variant='contained' >Get started</Button>
                                    </Paper>
                                );
                            })
                        }
                    </section>
                </div>

            </div>
        </div>

    )
}

export { RegistrationPlan}