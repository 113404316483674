import {Link} from 'react-router-dom'
export default function Navbar(){

    return(
<nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div className="container gap-3 gap-sm-3 pt-2 pb-2 d-flex justify-content-between" >
        <div className="flex-fill">
            <Link to={'/'} class="nav-link active">Wowesa</Link>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse flex-lg-fill" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <Link to={'/'} class="nav-link active">Home</Link>
                </li>
                <li class="nav-item dropdown">
                    <Link class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        History
                    </Link>
                <ul class="dropdown-menu">
                    <li><Link class="dropdown-item">School</Link></li>
                    <li><Link class="dropdown-item">Association</Link></li>
                </ul>
                </li>
                <li class="nav-item dropdown">
                    <Link class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Registration
                    </Link>
                <ul class="dropdown-menu">
                    <li><Link to={'/onGoing-Registration'} class="dropdown-item">On-going</Link></li>
                    <li><Link to={"/registration-Plan"} class="dropdown-item">Registration Plan</Link></li>
                    <li><Link to={"/registration-history"} class="dropdown-item">Registration History</Link></li>  {/*excel sheet*/}
                </ul>
                </li>
                <li class="nav-item dropdown">
                    <Link class="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Wowesa Executives 
                    </Link>
                <ul class="dropdown-menu">
                    <li><Link class="dropdown-item">Present-exco</Link></li>
                    <li><Link class="dropdown-item">Founding-exco</Link></li>
                    <li><Link class="dropdown-item">Other-exco</Link></li>
                </ul>
                </li>
                <li class="nav-item">
                    <Link class="nav-link" >Feedback</Link>
                </li>
                <li class="nav-item dropdown">
                    <Link  class="nav-link dropdown-toggle"  role="button" data-bs-toggle="dropdown" aria-expanded="false">News</Link>
                        <ul class="dropdown-menu">
                            <li><Link to={'/news'} class="dropdown-item">Convention</Link></li>
                        </ul>
                </li>
                <li class="nav-item">
                    <Link class="nav-link" >Donations</Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link" aria-current="page">Contact Us</Link>
                </li>
            </ul>
        </div>
        <div class="d-flex gap-3">
            <button class="btn btn-outline-success">Login</button>
            <button class="btn btn-outline-warning">Registration</button>
        </div>
    </div>
</nav>
    )
}