import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import historyPlanData from '../../document/WowesaRegistrationHistory.xlsx';

function RegistrationHistory(){
    const [excelData, setExcelData] = useState([]);
    const [rowsToShow, setRowsToShow] = useState(10); 

    useEffect(() => {
        const file = historyPlanData; // Update with your file path
        fetch(file)
          .then((res) => res.arrayBuffer())
          .then((data) => {
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            setExcelData(jsonData);
          });
    }, []);

    const handleLoadMore = () => {
        // Increase the number of rows to show by 10 on each click
        setRowsToShow(prevRows => prevRows + 10);
    };
    return(
        <div className="container">
            <div className="row">
                <div className='col-12 d-flex flex-column justify-content-center align-items-center gap-4 py-5'>
                     <h1 className="text-center">View Registration History</h1>
                     <TableContainer className='rounded-4' component={Paper} elevation={5}>
                        <Table>
                            <TableBody>
                            {excelData.slice(0, rowsToShow).map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex}>{String(cell)}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {excelData.length > rowsToShow && (
                        <Button className='px-4' variant="outlined" color="primary" onClick={handleLoadMore}>
                            Load More
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

export { RegistrationHistory };
