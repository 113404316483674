import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IMG1 from '../../images/ConventionPhotos/IMG1.jpg';
import IMG2 from '../../images/ConventionPhotos/IMG2.jpg';
import IMG3 from '../../images/ConventionPhotos/IMG3.jpg';
import IMG4 from '../../images/ConventionPhotos/IMG4.jpg';
import IMG5 from '../../images/ConventionPhotos/IMG5.jpg';
import IMG6 from '../../images/ConventionPhotos/IMG6.jpg';
import IMG7 from '../../images/ConventionPhotos/IMG7.jpg';
import IMG8 from '../../images/ConventionPhotos/IMG8.jpg';
import IMG9 from '../../images/ConventionPhotos/IMG9.jpg';
import IMG10 from '../../images/ConventionPhotos/IMG10.jpg';
import IMG11 from '../../images/ConventionPhotos/IMG11.jpg';
import IMG12 from '../../images/ConventionPhotos/IMG12.jpg';
import IMG13 from '../../images/ConventionPhotos/IMG13.jpg';
import IMG14 from '../../images/ConventionPhotos/IMG14.jpg';
import IMG15 from '../../images/ConventionPhotos/IMG15.jpg';
import IMG16 from '../../images/ConventionPhotos/IMG16.jpg';
import IMG17 from '../../images/ConventionPhotos/IMG17.jpg';
import IMG18 from '../../images/ConventionPhotos/IMG18.jpg';
import IMG19 from '../../images/ConventionPhotos/IMG19.jpg';
import IMG20 from '../../images/ConventionPhotos/IMG20.jpg';
import IMG21 from '../../images/ConventionPhotos/IMG21.jpg';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import wowesaPdf from '../../document/WOWESAConvention2023.pdf';
import Button from '@mui/material/Button';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function News() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box className="col-12 d-flex justify-content-center"  sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
        textColor="secondary"
        indicatorColor="secondary" 
        value={value} 
        onChange={handleChange} 
        aria-label="basic tabs example">
            
          <Tab label="Photos" {...a11yProps(0)} />
          <Tab label="Minutes" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
            <div class="container mt-5">
                    <div class="row">
                        <h2 className='text-center mb-5'>Convention Photos</h2>
                        <div class="col-12 d-flex justify-content-center align-items-center">
                            <Box sx={{ width: 800, height: 500, overflowY: 'scroll' }}>
                                <ImageList variant="masonry" cols={3} gap={8}>
                                    {itemData.map((item) => (
                                    <ImageListItem key={item.img}>
                                        <img
                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                        alt={item.title}
                                        loading="lazy"
                                        />
                                    </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>

                        </div>
                    </div>
            </div>  
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className='container'>
            <div className='row'>
                <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                  <h1>Wowesa Convention 2023</h1>
                  <Button endIcon={<PictureAsPdfOutlinedIcon/>} size='large' href={wowesaPdf} target='_blank' rel='noopener noreferrer' variant='outlined'>
                    View PDF
                  </Button>
                  
                </div>
            </div>
        </div>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel> */}
    </Box>
  );
}

const itemData = [
    {
        img: IMG1,
        title: 'Bed',
    },
    {
        img: IMG2,
        title: 'Books',
    },
    {
        img: IMG3,
        title: 'Sink',
    },
    {
        img: IMG4,
        title: 'Kitchen',
    },
    {
        img: IMG5,
        title: 'Blinds',
    },
    {
        img: IMG6,
        title: 'Chairs',
    },
    {
        img: IMG7,
        title: 'Laptop',
    },
    {
        img: IMG8,
        title: 'Doors',
    },
    {
        img: IMG9,
        title: 'Coffee',
    },
    {
        img: IMG10,
        title: 'Storage',
    },
    {
        img: IMG11,
        title: 'Candle',
    },
    {
        img: IMG12,
        title: 'Coffee table',
    },
    {
        img: IMG13,
        title: 'Image13 Title',
    },
    {
        img: IMG14,
        title: 'Image14 Title',
    },
    {
        img: IMG15,
        title: 'Image15 Title',
    },
    {
        img: IMG16,
        title: 'Image16 Title',
    },
    {
        img: IMG17,
        title: 'Image17 Title',
    },
    {
        img: IMG18,
        title: 'Image18 Title',
    },
    {
        img: IMG19,
        title: 'Image19 Title',
    },
    {
        img: IMG20,
        title: 'Image20 Title',
    },
    {
        img: IMG21,
        title: 'Image21 Title',
    },
];


// function News(){

//     return(
//         <div class="container mt-5">
//             <div class="row">
//                 <h2 className='text-center mb-5'>Convention Photos</h2>
//                 <div class="col-12 d-flex justify-content-center align-items-center">
//                     <Box sx={{ width: 800, height: 500, overflowY: 'scroll' }}>
//                         <ImageList variant="masonry" cols={3} gap={8}>
//                             {itemData.map((item) => (
//                             <ImageListItem key={item.img}>
//                                 <img
//                                 srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
//                                 src={`${item.img}?w=248&fit=crop&auto=format`}
//                                 alt={item.title}
//                                 loading="lazy"
//                                 />
//                             </ImageListItem>
//                             ))}
//                         </ImageList>
//                     </Box>

//                 </div>
//             </div>     
//             <div className='row'>
//                 <h2 className='text-center mt-5'>Meeting of esco</h2>
//                 <div className='col-12 d-flex justify-content-center align-items-center'>
//                     <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
//                         <div class="carousel-inner">
//                             <div class="carousel-item active">
//                                 <img src={itemData[0].img} alt={itemData[0].title} class="d-block w-100"/>
//                             </div>
//                             {
//                                 itemData.slice(1).map((item)=>{
//                                     return (
//                                         <div class="carousel-item">
//                                             <img src={item.img} alt={item.title} class="d-block w-100"/>
//                                         </div>
//                                     )
                                    
//                                 })
//                             }
//                         </div>
//                         <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
//                             <span class="carousel-control-prev-icon" aria-hidden="true"></span>
//                             <span class="visually-hidden">Previous</span>
//                         </button>
//                         <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
//                             <span class="carousel-control-next-icon" aria-hidden="true"></span>
//                             <span class="visually-hidden">Next</span>
//                         </button>
//                     </div>
//                 </div>
//             </div>
            
//         </div>
        
//     )
// }
export{News}