function OnGoingRegistration() {
    return(
        <div className="mt-5" >
            <div className="container">
            <p className="text-center">
            Registration of New Members / annual dues payment of new and old members. 
Annual due of 5000frs is payable between 31st December and March 30th of each year. 
Registered members who fail to comply within this time frame shall forfeit all statutory benefits and may only regain their rights 90 days after the fulfilment of the above-mentioned payment. 
Meanwhile, newly registered members can only benefit after *120 DAYS* following the payment of both registration fees and annual due. 
PLEASE CHANNEL YOUR CONTRIBUTIONS VIA WOWESA ACCOUNT (FOMBANG RICHARD
MTN MOBILE MONEY +237/673 417 112)
FOR THOSE IN AMERICA, USE THIS CHANNEL FOR PAYMENT.
  
ZELLE to
wowesanamerica@gmail.com
Or
Quinta.mancho@comcast.net
$10 renewing registration 
$12 for newly registered members
  
UNITED KINGDOM CONTACT DETAILS:
Please contact the chapter for your contributions. 

        </p>
            </div>

        <p className="text-center">
        Names of those who have paid their annual and registration fees according to the national data.
        </p>
            <div className="col-12 d-flex justify-content-center">
                <ol className="text-start mt-3 d-flex flex-column ">
                    <li>Akalefac Shardrack Lekeaka (348) 5000frs</li>
                    <li>Oyibo Victor (217) 5000frs</li>
                    <li>Haddison Jacobine (119) 5000frs</li>
                    <li>Dr. Tanah Richard (65) 5,000frs</li>
                    <li>Fube Godlove Ngwa (268) 5,000frs</li>
                    <li>Tebah Richard Fobang (249) 5,000frs</li> 
                    <li>Ayuk JonasTakem (273) 5,000frs</li>
                    <li>Eseme Marbel (302) 5,000frs</li>
                    <li>Mesumbe Jonas Ebontane (272) 5,000frs</li>
                    <li>Magdaline Ikah Duh (151) 5,000frs</li>
                    <li>Maureen Mengot Ayuk (159) 5,000frs</li>
                </ol>
            </div>

        
        </div>

    )
}

export {OnGoingRegistration}