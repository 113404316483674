import Navbar from './components/Navbar/Navbar';
import React from 'react';
import {BrowserRouter as Router, Route,Routes} from 'react-router-dom'
import { Home } from './pages/Home/Home';
import {RegistrationPlan} from './pages/registration/registration'
import { OnGoingRegistration } from './pages/registration/onGoingRegistration';
import { RegistrationHistory } from './pages/registration/registrationHistory';
import { News } from './pages/News/News';
function App() {
  return (
    <Router>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/registration-Plan' element={<RegistrationPlan/>}/>
        <Route path='/onGoing-Registration' element={<OnGoingRegistration/>} />
        <Route path='/registration-history' element={<RegistrationHistory/>}/>
        <Route path='/news' element={<News/>}/>
      </Routes>
    </Router>
  );
}

export default App;
// news- T-Convention Photos - Meetings of esco-meetings