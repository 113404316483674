import wowesa from '../../images/Wowesa_Logo.jpg'

function Home(){
    return(
        <div class="container">
            <div class="row">
                <h1 className='text-center text-danger'>WORLDWIDE MISSIONS HIGH SCHOOL MPUNDU-MUYUKA, <br/> EX-STUDENTS' ASSOCIATION</h1>
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <img src={wowesa} width={250} height={250} alt='WowesaIcon' class="img-fluid"/>
                </div>
            </div>
        </div>
    )
}
export {Home}